.App {
  text-align: center;
}

body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#whatsAppLogo {
    width: 40%;
    height: 40%;
}

a:hover {
  text-decoration: none;
}
a:not([href]) {
  cursor: pointer;
  color: #0000EE;
}

.jumbotron {
  background-image: url("./dark-mosaic.png");
  background-size: initial;
  background-color: #130c3b;
  color: aliceblue;
}
nav {
  background-image: url("./dark-mosaic.png");
  background-size: initial;
  background-color: #130c3b;
  color: aliceblue;
}

@media only screen and (min-width: 700px) {
  .card-body {
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media only screen and (max-width: 1000px) {
  #whatsAppLogo {
    width: 60%;
    height: 60%;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.container {
  margin-left: 0;
}

.image-div {
  max-width: 100%;
}

#alignLeft {
  text-align: left;
  align-items: left;
}
.card-body {
  text-align: left;
  align-items: flex-start;
}
/* #AboutCard {
  background-color:cadetblue;
  border-color:darkblue;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
